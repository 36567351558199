import React from 'react';
import {CreateNests} from "../CreateNests/createNests";


function Start(props) {
    if(props.hasNests) {
        return (<div>has nests</div>);
    }
    return (<CreateNests></CreateNests>);

}

export {Start};
