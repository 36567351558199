import './App.css';
import { Router } from "@reach/router";
import {Start} from "./components/Start/start";

let Dash = () => <div>Dash</div>

function App() {
  return (
      <Router>
          <Start path="/"></Start>

        <Dash path="dashboard" />
      </Router>
  );
}

export default App;
